<template>
  <div class="page">
    <section>
      <div class="max-w-wrap">
        <h2>
          Books 
        </h2>
        <div class="two-up books">
          <BookInfo size="small" title="Veiled Scarlet" descShort="Confined to a maze-like manor, the Countess’s daughter and her servant question whether the house is keeping them safe or hiding who they really&nbsp;are." folder="veiled-scarlet" cover="cover-667x1000.jpg" />
          <BookInfo size="small" title="Damn Wilds" descShort="Fast-paced, modern western-fantasy with magic-users and gunslingers stealing cars and killing monsters." folder="damn-wilds" cover="cover-1000x1500.jpg" />
        </div>
      </div>
    </section>
    <section>
      <div class="max-w-wrap">
        <h2>
          Stories 
        </h2>
        <div class="two-up stories">
          <StoryInfo title="Where Everything Stays" venue="Dark Matter Ink" dataTrack="story-where-everything-stays" link="https://darkmattermagazine.shop/products/dark-matter-presents-the-off-season" />
          <StoryInfo title="Pearls" venue="JAKE" dataTrack="story-pearls" link="https://jakethemag.com/pearls/" />
          <StoryInfo title="The Man Who Dances With Rats" venue="Bullshit Lit" dataTrack="story-man-who-dances-with-rats" link="https://www.bullshitlit.com/post/a-thing-by-k-a-honeywell" />
          <StoryInfo title="Amy and Ghost" venue="jmww" dataTrack="story-amy-and-ghost" link="https://jmwwblog.wordpress.com/2023/06/07/flash-fiction-amy-and-ghost-by-k-a-honeywell/" />
          <StoryInfo title="gone song" venue="tiny wren lit" dataTrack="story-gone-song" link="https://www.tinywrenlit.com/product-page/all-poems-are-ghosts" />
          <StoryInfo title="Isabella, or the Crack of Eggshells" venue="The Lovers Literary Journal" dataTrack="story-isabella" link="https://theloversliteraryjournal.com/issues#" />
          <StoryInfo title="Companion" venue="Flash Point SF" dataTrack="story-companion" link="https://flashpointsf.com/2023/02/25/companion/" />
          <StoryInfo title="All these oceans" venue="Emerge Journal" dataTrack="story-all-these-oceans" link="https://emergeliteraryjournal.com/all-these-oceans/" />
          <StoryInfo title="Grandmother" venue="Toil &amp; Trouble" dataTrack="story-grandmother" link="https://695e06c7-2b6b-40db-9da0-cd7e0eeeb060.filesusr.com/ugd/e38b3b_d2aa572e1f6e4cea9581af997a4465bc.pdf" />
          <StoryInfo title="Bread to Bring Them Back, or Keep Them" venue="Deathcap &amp; Hemlock" dataTrack="story-bread-to-bring-them" link="https://www.deathcapandhemlock.com/breadtobring" />
          <StoryInfo title="A fish walks onto the bus" venue="HAD" dataTrack="story-a-fish-walks-onto-the-bus" link="https://www.havehashad.com/hadposts/a-fish-walks-onto-the-bus" />
          <StoryInfo title="Relikviya" venue="Tiny Molecules" dataTrack="story-relikviya" link="https://www.tinymolecules.com/issues/thirteen#k-a-honeywell" />
          <StoryInfo title="Is That Structure-Compromising Gas In Your Exosuit, Or Are You Just Happy to See Me?" venue="Idle Ink" dataTrack="story-exosuit" link="https://idleink.org/2022/05/07/is-that-structure-compromising-gas-in-your-exosuit-or-are-you-just-happy-to-see-me-by-k-a-honeywell/" />
          <StoryInfo title="Where She Isn't" venue="Rejection Letters" dataTrack="story-where-she-isnt" link="https://rejection-letters.com/2022/04/25/where-she-isnt-k-a-honeywell/" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BookInfo from '@/components/BookInfo.vue';
import StoryInfo from '@/components/StoryInfo.vue';
import { useMeta } from 'vue-meta'

export default {
  name: 'App',
  components: {
    BookInfo,
    StoryInfo
  },
  setup () {
    useMeta({ title: 'Publications' })
  }
}
</script>

<style lang="scss" scoped>
.two-up {
  &.books {
    display: flex;
    gap: 150px;

    .book-wrap {
      width: 50%;
    }
  }

  @media screen and (max-width: 1024px) {
    &.books {
      flex-direction: column;
      gap: 50px;

      .book-wrap {
        width: 100%;
      }
    }
  }

  &.stories {
    columns: 2;
    gap: 150px;

    .story-wrap + .story-wrap {
      margin-top: 45px;
    }

    .story-wrap {
      break-inside: avoid;
    }

    @media screen and (max-width: 1024px) {
      columns: 1;
    }
  }
}
</style>